<template>
  <div class="content_confirmation">
    <Menu2></Menu2>
    <Menu></Menu>
    <div class="content-box">
        <h4>¡Gracias!</h4>
        <p>Tu solicitud se ha enviado con éxito</p>
        <router-link class="btn-confirmacion" to="/">Volver</router-link>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Menu2 from '@/components/Menu2.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'confirmacion',
  components: {
    Menu,
    Menu2,
    Footer,
  },
   data() {
    return {}
   },
    methods: {},
};
</script>
<style scoped>

.content-box{
    background-color: #f2f2f2;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btn-confirmacion{
  border: 1.5px solid #4ff5dd;
  background-color:#4ff5dd ;
  border-radius: 20px;
  width: 100px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #525bb7;
  font-weight: 600;
  text-decoration: none;
}

@media (max-width: 991px){

}
   
@media (min-width: 992px){
    .content-box{
        height: 90vh;
    }
}

</style>